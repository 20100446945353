<header class="content__header" *ngIf="Model">
  <div class="content__header-title" [ngSwitch]="Model.id">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" *ngSwitchCase="null">Создание клиента</span>
      <span class="content__header-title-value" *ngSwitchDefault>Редактирование клиента</span>
    </div>
    <div>
      <a class="content__header-link" (click)="close()">
        Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="Model">
  <div class="row" *ngIf="Model.id" @animation>
    <div class="col-2 col-sm-2 col-md-2 col-lg-2" style="text-align: -webkit-center">
      <div class="avatar" style="width: 9em; height: 9em;">
        <div class="avatar__default">
          <span class="sp sp-user" style="font-size: 4rem" *ngIf="!isImageProfile"></span>
        </div>
        <img class="avatar__img" *ngIf="isImageProfile" [src]="ImageProfile">
      </div>
    </div>
    <div class="col-10 col-sm-10 col-md-10 col-lg-10">
      <div class="short-info d-flex" style="margin-bottom: 0; align-items: flex-start; justify-content: space-between">
        <ul>
          <li *ngIf="Model.accountStatus !== 'clubClient' && Model.accountStatus === 'registered'"><span class="sp sp-sp" title="Пользователь приложения SP"></span></li>
          <li>Имя: <b>{{Model.lastName}} {{Model.firstName}}</b></li>
          <li *ngIf="Model.phone">Телефон: <b>{{Model.phone}} </b></li>
          <li *ngIf="Model.email">Email: <b>{{Model.email}} </b></li>
          <li *ngIf="Model.birthday">День рождения: <b>{{Model.birthday}} </b></li>
          <li *ngIf="Model.description">Комментарий: <b style="word-break: break-all;">{{Model.description}}</b></li>
        </ul>
        <button type="button" (click)="setShowDetails()">{{!isShowDetails ? '[Показать]' : '[Скрыть]'}}</button>
      </div>
      <div *ngIf="(Phone || Model.accountStatus === 'registered') && !isShowDetails">
          <div class="box-title message" style="justify-content: flex-start; padding: 0; margin: 0; padding-left: 25px;">Написать клиенту:
            <div class="btn-group" style="margin-left: 20px">
              <a class="btn btn-link btn-message" style="font-size: 2rem" rel="whatsapp" href="https://wa.me/{{Phone}}" target="_blank" *ngIf="Phone">
                <span class="sp sp-whatsapp"></span>
              </a>
              <a class="btn btn-link btn-message" style="font-size: 2.4rem" href="https://t.me/+{{Phone}}" target="_blank" *ngIf="Phone">
                <span class="sp sp-telega"></span>
              </a>
              <a class="btn btn-link btn-message" style="font-size: 2.4rem" *ngIf="isRequestChatAvailable && Model.id != null && Model.accountStatus === 'registered'" (click)="requestChat()">
                <span class="sp sp-logo"></span>
              </a>
              <a class="btn btn-link btn-message" style="font-size: 2rem" rel="viber" href="viber://chat?number=%2B{{Phone}}" *ngIf="Phone && isShowBtnViber">
                <span class="sp sp-viber"></span>
              </a>
            </div>
          </div>
      </div>
    </div>
  </div>

  <form [formGroup]="form" novalidate>

      <ng-container *ngIf="isShowDetails">
        <div class="row" style="padding-left: 10px; padding-right: 10px;">
      <div class="col-12 col-sm-4 col-md-4 col-lg-4">
        <div class="form-group padding-for-photo">
          <div class="row__field">
            <app-gallery-cva [ngModel]="Model.medias" [multipleUpload]="false" formControlName="medias" [takePhoto]="true"
              [title]="'Фото'" (onItemOpen)="openImage($event)" [showPhoto]="false" (changePhoto)="changePhotoUser($event)"></app-gallery-cva>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-8 col-md-8 col-lg-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">Имя</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" id="firstName" formControlName="firstName"
              [ngModel]="Model.firstName" maxlength="500">
            <label for="firstName" class="field-validation-error"
              *ngIf="fieldErrors.firstName && isSubmited">{{fieldErrors.firstName}}</label>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-8 col-md-8 col-lg-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">Фамилия</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" id="lastName" formControlName="lastName"
              [ngModel]="Model.lastName" maxlength="500">
            <label for="lastName" class="field-validation-error"
              *ngIf="fieldErrors.lastName && isSubmited">{{fieldErrors.lastName}}</label>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-8 col-md-8" *ngIf="Model.id && Model.accountStatus === accountStatus.registered">
        <div class="clue-text" *ngIf="confirm">
          В приложении у клиента введены следующие имя и фамилия: {{Model.firstNameSP}} {{Model.lastNameSP}}.
          <button class="btn-link" (click)="useFromSP()">Скопировать в карточку</button>
        </div>
      </div>

     <!-- <div class="col-12 col-sm-8 col-md-8 col-lg-8">
        <div *ngIf="Model.hasClubChanges" class="info-text">
          Имя и/или фамилия были изменены клубом. При изменении клиентов в приложении, здесь они не будут обновлены
        </div>
      </div>-->

      <div class="col-12 col-md-12 col-lg-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">Телефон</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" id="phone" formControlName="phone"
              [ngModel]="Model.phone">
            <label for="phone" class="field-validation-error"
              *ngIf="fieldErrors.phone && isSubmited">{{fieldErrors.phone}}</label>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">Email</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" id="email" formControlName="email"
              [ngModel]="Model.email">
            <label for="email" class="field-validation-error"
              *ngIf="fieldErrors.email && isSubmited">{{fieldErrors.email}}</label>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-lg-8" style="float: right" *ngIf="Model.id && Model.accountStatus === accountStatus.registered">
        <div class="clue-text" *ngIf="confirmContact">
          В приложении у клиента введены следующие контактные данные: {{Model.phoneNumberSP}}, {{Model.emailSP}}.
          <button class="btn-link" (click)="useFromSPContact()">Скопировать в карточку</button>
        </div>
      </div>
          </div>
        <div class="row" style="padding-left: 10px; padding-right: 10px;">
      <div class="col-12 col-sm-12 col-lg-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">День рождения</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" id="birthday" formControlName="birthday"
              [ngModel]="Model.birthday" placeholder="В формате дд.мм.гггг">
            <label for="birthday" class="field-validation-error"
              *ngIf="fieldErrors.birthday && isSubmited">{{fieldErrors.birthday}}</label>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-lg-4" *ngIf="Phone || Model.accountStatus === 'registered'">
        <div class="form-group" myScrollTo>
          <div class="box-title">Написать клиенту</div>
          <div class="btn-group">
            <a class="btn btn-link btn-message" rel="whatsapp" href="https://wa.me/{{Phone}}" target="_blank" *ngIf="Phone">
              <span class="sp sp-whatsapp"></span>
            </a>
            <a class="btn btn-link btn-message" href="https://t.me/+{{Phone}}" target="_blank" *ngIf="Phone">
              <span class="sp sp-telega"></span>
            </a>
            <a class="btn btn-link btn-message" *ngIf="isRequestChatAvailable && Model.id != null && Model.accountStatus === 'registered'" (click)="requestChat()">
              <span class="sp sp-logo"></span>
            </a>
            <a class="btn btn-link btn-message" rel="viber" href="viber://chat?number=%2B{{Phone}}" *ngIf="Phone && isShowBtnViber">
              <span class="sp sp-viber"></span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-lg-4">
        <div class="form-group" myScrollTo>
          <div class="box-title">Пол</div>
          <div class="row__field">
            <select class="select select__long" formControlName="level" id="gender" [ngModel]="Model.gender"
              formControlName="gender">
              <option value="Male">мужской</option>
              <option value="Female">женский</option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="box-title" *ngIf="Model.spUserId">Aбонент Sport Priority</div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="form-group" myScrollTo>
          <div class="box-title">Комментарий</div>
          <div class="row__field">
            <textarea type="text" maxLength="402" placeholder="Комментарий" formControlName="description"
              id="description" [ngModel]="Model.description"></textarea>
              <span *ngIf="form.get('description').invalid" >
                <span class="validation-message" *ngIf="form.get('description').errors.maxlength">
                  Комментарий не должен
                  превышать {{form.get('description').errors.maxlength.requiredLength}} символов.
                </span>
              </span>
            <label for="description" class="field-validation-error"
              *ngIf="fieldErrors.description && isSubmited">{{fieldErrors.description}}</label>
          </div>
        </div>
      </div>
          </div>
      <div class="clearfix hidden-md-down"></div>

      <div class="col-xs-12" *ngIf="!isNotSaved()">
        <div class="info-text">

          <div>
            <span class="icon icon-about"></span>
            Статус:
            <ng-container *ngIf="isDraft()">Черновик</ng-container>
            <ng-container *ngIf="isPublished()">Опубликовано</ng-container>
            <ng-container *ngIf="isDeleted()">Удалено</ng-container>
          </div>

        </div>
      </div>

      <ng-container *ngIf="isEditAvailable && !isDeleted()">

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <button id="save-button" class="btn btn-primary" (click)="finishEdit()" [disabled]="isSubmited && form.invalid">
                <span class="btn-primary__desc">
                  <span app-key-help [key]="'saveText'"></span>
                  <span app-key-help mobile-hidden [key]="'saveHK'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="clearfix hidden-md-up"></div>

        <div class="clearfix hidden-md-up"></div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <ng-container *ngIf="Model.id != null">
                <button class="btn btn-primary" (click)="copyEdit()" [disabled]="isSubmited && form.invalid">
                  <span class="btn-primary__desc">
                    <span app-key-help [key]="'saveNew'"></span>
                  </span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="clearfix hidden-md-up"></div>

        <div class="col-xs-12 col-sm-6 col-md-4" *ngIf="isRemoveAvailable && Model.id != null && !isDeleted()">
          <div class="form-group">
            <div class="row__field">
              <ng-container *ngIf="Model.id != null">
                <button class="btn btn-link" (click)="delete()">
                  <span app-key-help [key]="'deleteText'"></span>
                  <span app-key-help mobile-hidden [key]="'deleteHK'"></span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>

      </ng-container>

      <div class="clearfix hidden-md-up"></div>

      </ng-container>

      <div class="col-xs-12 col-sm-12 col-md-12" *ngIf="Model.id">
        <div class="box-title">Абонементы</div>
        <div class="row__field">
          <div class="clients-container">
            <div class="tableSideLinkWrap">
              <div class="clue-text">
                <span class="key" app-key-help [key]="'search'"></span>
                <span class="key" app-key-help [key]="'searchHK'"></span>
                <a class="box-link" (click)="showSubscriptions()" *ngIf="isSubscriptionAddAvailable">Добавить
                  <span class="key">
                    <span app-key-help [key]="'plus'"></span>
                  </span>
                </a>
              </div>
            </div>

            <div class="form-group">
              <app-abonement-list></app-abonement-list>
            </div>

          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12" *ngIf="clientGroup && clientGroup.length">
        <div @animation class="form-group">
          <div class="box-title">Группы</div>
          <ul class="group-list">
            <li *ngFor="let group of clientGroup;">
              <span class="btn btn-link" (click)="showGroup(group, $event)" (contextmenu)="showGroup(group, $event)">{{group.name}}</span>
              <button type="button" class="btn-clear" (click)="removeClientGroup(group)">
                <span class="icon-cross__left"></span>
                <span class="icon-cross__right"></span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="box-title">История записей</div>
        <div class="row__field">
          <div class="form-group">
            <div class="table-responsive">
              <table class="table rowHover">
                <thead>
                  <tr>
                    <td class="table-cell table-title-cell">№</td>
                    <td class="table-cell table-title-cell">Название занятия</td>
                    <td class="table-cell table-title-cell">Дата начала</td>
                    <td class="table-cell table-title-cell">Статус</td>
                    <td class="table-cell table-title-cell">Абонемент</td>
                  </tr>
                </thead>
                <tbody *ngIf="history && history.length">
                  <tr *ngFor='let item of history; let i = index' (click)="goToClubSchedule(item.class.id, item.class.startTime, item.id, $event)"
                      (contextmenu)="goToClubSchedule(item.class.id, item.class.startTime, item.id, $event)">
                    <td class="table-cell"><span class="thead">№:</span>{{ 1 + i }}</td>
                    <td class="table-cell"><span class="thead">Название занятия:</span>{{ item.class.name }}</td>
                    <td class="table-cell"><span class="thead">Дата начала:</span>{{ item.class.startTime | time2string }} {{ item.class.startTime | date: 'EEEE':'UTC' }}</td>
                    <td class="table-cell"><span class="thead">Статус:</span>{{ item.currentStatusText }}</td>
                    <td class="table-cell"><span class="thead">Абонемент:</span>
                      {{ (item.subscription == null) ? 'Без абонемента' : (item.subscription.name == null) ? 'Без абонемента' : item.subscription.name }}
                      {{ (item.subscription != null) ? (item.subscription.fromDate != null ? ' от ' : null) : null}}
                      {{ (item.subscription != null) ? (item.subscription.fromDate == null ? null : item.subscription.fromDate | date:'dd.MM.yyyy') : null }}
                    </td>
                  </tr>
                </tbody>
                <tbody *ngIf="!(history && history.length)">
                <tr>
                  <td style="text-align: center" colspan="5" class="table-cell"><span class="thead">№:</span>Записей нет</td>
                </tr>
                </tbody>
              </table>
              <div class="pastTableDate" *ngIf="pastVisitsCount">И еще {{pastVisitsCount}} в прошедших сезонах.</div>
              <div class="pastTableDate" *ngIf="pastVisitsCount == 0">В прошедших сезонах нет записей.</div>
            </div>
          </div>
        </div>
      </div>

  </form>
</div>
