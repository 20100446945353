<header class="content__header">
  <div class="content__header-title">
    <div class="content__back" (click)="Back()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value">
        Редактирование абонемента
      </span>
    </div>
    <div class="">
      <a class="content__header-link" (click)="Back()">
        Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="formsExtension && extension">
  <form [formGroup]="formsExtension" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-xs-12 col-sm-8">
          <div class="form-group">
            <div class="box-title">
              Название абонемента:
            </div>
            <div class="row__field">
              {{extension && extension.name}}{{(extension.fromDate && extension.toDate ? ', с ' + extension.fromDate + ' до ' + extension.toDate : null)}} Создан: {{this.userInfo}}
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-8">
          <div class="form-group">
            <div class="box-title">
              Установить дату начала:
            </div>
            <div class="row__field">
              <app-date-picker [(ngModel)]="extension.fromDate"  placeholder="" format="DD.MM.YYYY" formControlName="fromDate"
            ></app-date-picker>
              <label for="fromDate" class="field-validation-error" *ngIf="fromDate == 'Invalid date'">
                {{fieldMessages.fromDate.dateValidator}}
              </label>
              <label class="field-validation-error" *ngIf="isPickDateTo">
                {{fieldMessages.fromDate.checkFromDate}}
              </label>
              <label class="field-validation-error" *ngIf="isMoreDateFrom">
                Дата начала не может быть больше даты конца
              </label>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-8">
          <div class="form-group">
            <div class="box-title">
              Продление даты:
            </div>
            <div class="row__field">
              <app-date-picker [(ngModel)]="extension.toDate"  placeholder="" format="DD.MM.YYYY" formControlName="toDate"
            ></app-date-picker>
              <label for="toDate" class="field-validation-error" *ngIf="extension.toDate == 'Invalid date'">
                {{fieldMessages.toDate.dateValidator}}
              </label>
              <label class="field-validation-error" *ngIf="isPickDateFrom">
                {{fieldMessages.toDate.checkToDate}}
              </label>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-8">
          <div class="form-group">
            <div class="box-title">
              Продление посещений (всего посещений):
            </div>
            <div class="row__field">
              <input type="number" class="input__text input__text--long" id="visitCount" formControlName="visitCount" [(ngModel)]="extension.visitCount" required>
              <label for="visitCount" class="field-validation-error" *ngIf="isControlInvalid('visitCount')">
                {{fieldMessages.visit.numberValidator}}
              </label>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-8" *ngIf="isChangePurchaseSubscrDate">
          <div class="form-group">
            <div class="box-title">
              Изменить дату оплаты:
            </div>
            <div class="row__field">
              <app-date-time-picker [(date)]="extension.purchasedAt" formControlName="purchasedAt" mode="daytime">
              </app-date-time-picker>
              <label for="purchasedAt" class="field-validation-error" *ngIf="extension.purchasedAt == 'Invalid date'">
                {{fieldMessages.dateBuy.dateValidator}}
              </label>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-12">
          <div class="form-group">
            <div class="box-title">
            </div>
            <div class="row__field">
              <button class="btn btn-primary" type="submit" [disabled]="!formsExtension.valid || extension.toDate == 'Invalid date' || checkDate()">
                <span class="btn-primary__desc">Сохранить</span>
              </button>
            </div>
          </div>
        </div>
    </div>
  </form>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12" *ngIf="abonementHistory && abonementHistory.length">
      <div class="box-title">История записей</div>
      <div class="row__field">
        <div class="form-group">
          <div class="table-responsive">
            <table class="table rowHover">
              <thead>
              <tr>
                <td class="table-cell table-title-cell">№</td>
                <td class="table-cell table-title-cell">Название занятия</td>
                <td class="table-cell table-title-cell">Дата начала</td>
                <td class="table-cell table-title-cell">Статус</td>
                <td class="table-cell table-title-cell">Абонемент</td>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor='let item of abonementHistory; let i = index' (click)="goToClubSchedule($event, item.class.id, item.class.startTime, item.id)">
                <td>{{ 1 + i }}</td>
                <td>{{ item.class.name }}</td>
                <td>{{ item.class.startTime | time2string }}</td>
                <td>{{ item.currentStatusText }}</td>
                <td>{{ (item.subscription == null) ? 'Без абонемента' : (item.subscription.name == null) ? 'Без абонемента' : item.subscription.name }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
