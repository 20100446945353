<header class="header" *ngIf="componentReady" [class._offline-mode]="offlineMode !== null">
  <!-- <div class="header__logo">
    <div class="header__club-title">{{clubNetName}}</div>
  </div> -->

  <div class="header__content wrapper" [ngClass]="{'header__content_right': !((currentClub || showCitySelector) && contextInfo)}">
    <ng-container *ngIf="!currentClub && currentCity && showCitySelector && contextInfo">
      <div class="header__club" appClickOutside (clickOutside)="onClickOutside($event, 'citylist')">
        <div class="header__club_name" (click)="isCityDropdownOpen = !isCityDropdownOpen" [ngClass]="{'hasList': cities.length > 1}">
          <span class="header__club-desc">{{currentCity.name}}</span>
        </div>
        <ul class="header__club_list _open" *ngIf="isCityDropdownOpen && cities.length">
          <li *ngFor="let city of cities" (click)="setCurrentCity(city); isCityDropdownOpen = false" [hidden]="city.id == currentCity.id">
            <span>{{city.name}}</span>
          </li>
        </ul>
      </div>
    </ng-container>
    <ng-container *ngIf="currentClub && contextInfo">
      <div [class.partner-logo]="isThemePartner" *ngIf="isThemePartner">
        <img src="./assets/partner-logo/partner-logo-stadion.png" alt="" />
      </div>
      <div class="header__club" appClickOutside (clickOutside)="onClickOutside($event, 'clublist')">
        <div class="header__club_name" (click)="isClubDropdownOpen = !isClubDropdownOpen" [ngClass]="{'hasList': contextInfo.clubs.length > 1}">
          <span class="header__club-desc">{{currentClub.name}}</span>
          <span *ngIf="contextInfo.clubs.length > 1" class="sp sp-arrow-small header__arrow-small_icon" [ngClass]="{'_opened': isClubDropdownOpen}"></span>
        </div>
        <ul class="header__club_list" [ngClass]="{_open: isClubDropdownOpen && contextInfo.clubs.length > 1}">
          <li *ngFor="let item of contextInfo.clubs" (mousedown)="setCurrentClub(item,$event)" (click)="setCurrentClub(item,$event); isClubDropdownOpen = false" [class.current]="item.id == currentClub.id">
            <span>{{item.name}}</span>
          </li>
        </ul>
      </div>
    </ng-container>
    <!-- <div class="header__changes-history">
      <span class="sp sp-ring header__ring_icon"></span>
      <span>История изменений</span>
    </div> -->

    <div class="header__search" [class.search_start]="searchStart" (click)="$event.stopPropagation()" appClickOutside (clickOutside)="searchStart=false; searchBox.value = null" *ngIf="currentClub && contextInfo && _isPermit('isHeaderSearchBarAvailable')">
        <form class="header__search-form" (ngSubmit)="scannerSearch($event, searchBox.value)">
          <input class="input__text input__text--long" autocomplete="off" #searchBox id="search-box"
                 (keyup)="searchClients($event, searchBox.value)" placeholder="Имя, телефон, email клиента"/>
          <button type="submit" style="display: none;"></button>
          <span class="icon-cross icon-clear-search" *ngIf="searchBox.value.length > 0" (click)="searchBox.value = null">
            <span class="icon-cross__left"></span>
            <span class="icon-cross__right"></span>
          </span>
        </form>

        <a class="search__btn" (click)="searchStart = true; searchBox.focus()">
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5002 17L22.0002 20" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <circle cx="11.6255" cy="11.0568" r="8.9479" transform="rotate(8.72512 11.6255 11.0568)" stroke="#ffffff" stroke-width="2"/>
          </svg>
        </a>

        <div class="search__list" *ngIf="clientSearchList && clientSearchList.length" appClickOutside
            (clickOutside)="clientSearchList = []">
          <ng-container *ngFor="let client of clientSearchList; index as i">
            <a class="search__list-item" [ngClass]="cursor === (i+1) ? 'active' : null" *ngIf="client" (click)="goClient(client)">
              <span class="search-name">{{client.firstName}} {{client.lastName}}</span>
              <span class="phone" *ngIf="client.phone">{{client.phone}}</span>
              <span class="sp sp-arrow-small icon-list-go"></span>
            </a>
          </ng-container>
        </div>
    </div>

    <div class="header__profile">
      <div appClickOutside (clickOutside)="onClickOutside($event, 'profile')">
        <div class="header__profile_name" (click)="isProfileDropdownOpen = !isProfileDropdownOpen">
        <span class="photo">
          <span class="sp sp-avatar header__avatar-default_icon"></span>
        </span>
          <span class="name">{{userInfo.firstName}} {{userInfo.lastName}}</span>
          <span class="sp sp-arrow-small header__arrow-small_icon" [ngClass]="{'_opened': isProfileDropdownOpen}"></span>
        </div>
        <ul class="header__profile_list" [ngClass]="{_open: isProfileDropdownOpen}">
          <li class="name__mob">
            {{userInfo.firstName}} {{userInfo.lastName}}
          </li>
          <li *ngIf="showSettings">
            <span (click)="moveToSettings(); isProfileDropdownOpen = false">Настройки аккаунта</span>
          </li>
          <li>
            <span (click)="logout()">Выйти</span>
          </li>
        </ul>
      </div>
    </div>
  </div>

</header>
