import { ExcelService } from './../../common/services/excel.service';
import {Component, ElementRef, TemplateRef, ViewChild} from '@angular/core';
import {ClientsService} from './services/clients.service';
import {TableListComponent} from 'app/common/table-list/table-list.component';

import {Injector} from '@angular/core';

import { FormControl } from '@angular/forms';
import { from,  Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { map, startWith, distinctUntilChanged, debounceTime, skip } from 'rxjs/operators';

import { IMergeClientModel } from '../../common/models/client.model';
import * as moment from 'moment';
import {ChatService} from '../chats/chats.service';
import { AlertsService } from 'app/common/components/alerts/services/alerts.service';
import { MergeGroup } from './client-merge-items/client-merge-items.component';
import {ClubSettingService} from '../club-settings/club-settings.service';
import {ClubSetting} from '../../common/models/club-setting.model';
import {LocalPreloader, PreloaderService} from '../../common/services/preloader.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent extends TableListComponent<any> {
  public static readonly componentName: string = 'ClientsComponent';

  @ViewChild('tmplMergeManualGroup') tmplMergeManualGroup: TemplateRef<any>;
  private readonly alertsService: AlertsService;

  public isExcelExportAvailable: boolean;
  public TermSearchValue = '';
  public tapIdClient: string;
  public sortOption = '';

  public clubSettings: ClubSetting[];
  public isShowBtnViber = false;

  constructor(
    injector: Injector,
    protected service: ClientsService,
    private excelService: ExcelService,
	private settingsService: ClubSettingService,
    private preloaderService: PreloaderService,
    public chatService: ChatService) {
    super(injector, service);
    this.alertsService = injector.get(AlertsService);
  }

  public async MergePopup(clientId: string, mergeList: IMergeClientModel[], group?: { merging: boolean }) {
    mergeList = mergeList
      .map(a => ({ ...a, isRelatedSpAccount: a['accountStatus'] !== 'clubClient' && a['accountStatus'] === 'registered' }))
      .sort((a, b) => (a.isRelatedSpAccount && !b.isRelatedSpAccount)
        ? -1
        : (moment(a.clubRegisteredAt, "DD.MM.YYYY").unix() - moment(b.clubRegisteredAt, "DD.MM.YYYY").unix()));
    const groups = this.mapMergeGroups([ { mergeList } ], clientId);
    if (await this.confirmationDialog(groups, clientId)) {
      // this.alertsService.showConfirm({ header: 'Заглушка', message: 'Ну типа слияние', buttons: [] });
      this.Merge(clientId, mergeList, group);
    }
  }

  private confirmationDialog(mergeGroups: MergeGroup[], clientId: string = undefined) {
    return new Promise<boolean|undefined>(resolve => setTimeout(() => {
      this.alertsService.showConfirm({
        header: 'Подтвердите слияние',
        template: { ref: this.tmplMergeManualGroup, context: { mergeGroups, clientId } },
        buttons: [{
          title: 'Объединить',
          callback: async () => {
            resolve(true);
          }
        }, {
          title: 'Отменить'
        }]
      });
    }));
  }

  public ngOnInit() {
    super.ngOnInit();
    this.authService.getUserInfo().then(res => this.isExcelExportAvailable = this.permissionService.isAvailable(this, 'isExcelExportAvailable', res?.role));

    combineLatest(
      this.searchTerm.valueChanges.pipe(startWith(''), map((query: string) => query.trim().length >= 2 ? query.trim() : undefined)),
      this.sortOptionValue,
    ).pipe(
    debounceTime(400),
    distinctUntilChanged((x, y) => x[0]==y[0] && x[1][0]==y[1][0] && x[1][1]==y[1][1]),
    skip(1))
    .subscribe(([query, [sort, mode]]) => {
      this.pageIndex = 0;
      this.search(query, sort, mode);
      // this.service.filterAndSort(query, sort, mode, this.pageIndex, this.pageSize).then( r => {
      //   this.Model = { clients: r.items };
      // });
    });

    this.settingsService.getClubSettingsByClub()
      .then(settings => {
        this.clubSettings = settings;
        const alias = this.clubSettings.find(s => s.alias === 'IsShowBtnViber');
        this.isShowBtnViber = alias.value === 'true';
      });

    if (super.isEditAvailable)
      this.GetMergeGroups();
  }

  async updateModel() {
    let orderBy = undefined;
    let sortMode = undefined;
    if (localStorage.getItem('sortTableClient')) {
      const sortTableClient = JSON.parse(localStorage.getItem('sortTableClient'));
      orderBy = Object.keys(sortTableClient)[0];
      sortMode = sortTableClient[orderBy];
      this.sortOption = orderBy + '-' + sortMode;
      this.sortOptionValue.next([orderBy, sortMode]);
    }
    if (localStorage.getItem('filterTableClient')) {
      const pattern = JSON.parse(localStorage.getItem('filterTableClient')).pattern;
      if (pattern) {this.TermSearchValue = pattern}
    }

    const res = await this.service.filterAndSort(undefined, orderBy, sortMode, this.pageIndex, this.pageSize);
    this.Model = { clients: res.items };
    this.totalItemCount = res.pagingInfo.totalItemCount;

    if (localStorage.getItem('pageIndexTableClient')) {
      const pageIndex = JSON.parse(localStorage.getItem('pageIndexTableClient')).pageIndex;
      if (pageIndex) {await this.GetPageIndex(pageIndex, orderBy, sortMode)}
    }

    this.onModelLoadCallback();
    if (localStorage.getItem('tapClientTableId')) {
      const clientId = JSON.parse(localStorage.getItem('tapClientTableId')).id;
      if (clientId) { this.tapIdClient = clientId}
    }
    setTimeout(() => {if (this.tapIdClient) {localStorage.removeItem('tapClientTableId')} this.tapIdClient = null}, 3000)
  }
  async GetPageIndex(index: number, orderBy: string, sortMode: string) {
    for (const i of [...Array(index).keys()]) {
      this.pageIndex = i + 1;
      const res = await from(this.service.filterAndSort(undefined, orderBy, sortMode, this.pageIndex, this.pageSize)).toPromise()
      this.Model.clients = (this.Model.clients as any[]).concat(res.items);
      this.totalItemCount = res.pagingInfo.totalItemCount;
      this.receivClientIdStack = [];
    }
  }

  public searchTerm = new FormControl();
  public sortOptionValue = new BehaviorSubject<[string, 'asc' | 'desc' | undefined]>(['', undefined]);
  public sortAsc: string;
  public sortDsc: string;

  private readonly pageSize = 50;
  public totalItemCount: number;
  public pageIndex = 0;

  public allClients: any[]; // Все клиенты клуба. (Для экспорта в excel)

  public sortOptionChange(val: string) {
    if (this.sortOptionValue.value[0] !== val || this.sortOptionValue.value[1] !== 'asc') {
      this.sortOption = val + '-asc';
      this.sortOptionValue.next([val, 'asc']);
      localStorage.setItem('sortTableClient', JSON.stringify({ [val]: 'asc' }));
    } else {
      this.sortOption = val + '-desc';
      this.sortOptionValue.next([val, 'desc']);
      localStorage.setItem('sortTableClient', JSON.stringify({ [val]: 'desc' }));
    }
  }

  public changeSortOptionTable(sortOption: any) {
    const sort = sortOption.split('-');
    this.sortOptionValue.next([sort[0], sort[1]]);
    localStorage.setItem('sortTableClient', JSON.stringify({ [sort[0]]: sort[1] }));
  }

  public showNextPage() {
    this.pageIndex += 1;
    this.search(this.searchTerm.value || '', this.sortOptionValue.value[0], this.sortOptionValue.value[1]);
    this.DeselectAll();
    localStorage.setItem('pageIndexTableClient', JSON.stringify({ pageIndex: this.pageIndex }));
  }

  public getCellTitleClass(name: string): string {
    var res = 'table-cell table-title-cell';
    if (this.sortOptionValue.value[0] === name) return res + ' cell-sort-' + this.sortOptionValue.value[1];
    return res;
  }

  public search(pattern: string, orderBy: string, sortMode: string) {
    this.service.filterAndSort(pattern, orderBy, sortMode, this.pageIndex, this.pageSize)
      .then(res => {
        if (this.pageIndex) {this.Model.clients = (this.Model.clients as any[]).concat(res.items)} else {this.Model.clients = res.items}
        this.totalItemCount = res.pagingInfo.totalItemCount;
        this.receivClientIdStack = [];
      });
    localStorage.setItem('filterTableClient', JSON.stringify({ pattern }));
    if (!this.pageIndex) {localStorage.removeItem('pageIndexTableClient')}
  }

  public mergeGroups: MergeGroup[];

  private mapMergeGroups(groups: { mergeList: IMergeClientModel[] }[], clientId?: string) {
    return groups.map(group => {
      var values = { firstName: [], lastName: [], phone: [], email: [] };
      const client = group.mergeList.find((c, i) => clientId ? clientId === c.id : i === 0);
      Object.keys(values).forEach(prop => {
        values[prop].push({ value: client[prop] });
      });
      return { mergeList: group.mergeList, values: values };
    });
  }

  async GetMergeGroups() {
    var res = await this.service.getMergeGroups();
    this.mergeGroups = this.mapMergeGroups(res);
  }

  public async AddIgnore(clientId: string) {
    await this.service.ignoreMergeGroups(clientId);
    this.GetMergeGroups();
  }

  public async Merge(clientId: string, mergeList: IMergeClientModel[], group?: { merging: boolean }) {
    if (group) {
      group.merging = true;
    }
    else { // ручной режим
      if (this.isInMerging) return;
      this.isInMerging = true;
    }
    try {
      await this.service.merge(clientId, mergeList.filter(x => x.id != clientId).map(x => x.id));
      this.DeselectAll();
      this.GetMergeGroups();
      this.search(this.searchTerm.value || '', this.sortOptionValue.value[0], this.sortOptionValue.value[1]);
    } finally {
      group && (group.merging = false);
      this.isInMerging = false;
    }
  }

  public receivClientIdStack: IClientModelSelected[] = [];
  public isInMerging: boolean = false;

  public Select(client: IClientModelSelected) {
    if (!this.isEditAvailable) return;
    if (this.isInMerging) return;

    if (client.selected) {
      client.selected = false;
      this.receivClientIdStack.splice(this.receivClientIdStack.indexOf(client), 1);
    } else {
      client.selected = true;
      this.receivClientIdStack.unshift(client);
    }
  }

  public DeselectAll() {
    while(this.receivClientIdStack.length)
      this.receivClientIdStack.pop().selected = false;
  }

  public async export() {
    this.allClients = (await this.service.filterAndSort(undefined, undefined, undefined, 0, 0)).items;
    const _allClients = this.allClients.filter(x => x.entityStatus === 'published');
    const clientsExport: any[] = [];
    _allClients.forEach(m => {
      const client: any =  {
        'ФИО': m.fullName,
        'Телефон': m.phone,
        'Email': m.email,
        'Абонемент': (m.subsciption.length > 0 && this.getLastSubscription(m.subsciption) != null ? this.getLastSubscription(m.subsciption)?.last + ' ' + this.getLastSubscription(m.subsciption)?.startDate + ' - ' + this.getLastSubscription(m.subsciption)?.endDate : ''),
        'Дата рождения': m.birthday,
        'Дата регистрации': m.clubRegisteredAt,
        'Комментарий': m.description
      };
      clientsExport.push(client);
    })
    this.excelService.exportAsExcelFile(clientsExport, `Клиенты-${moment().format("d.MM.YYYY-HH.mm")}`);
  }

  getLastSubscription(subs: any) {
    const correctSubs = subs.filter((s) => s.status === 'created' || s.status === 'active');
    // s.fromDate !== null && s.toDate !== null &&

    if (correctSubs.length > 1) {
      const most_recent = correctSubs.reduce((mostRecent, s) =>
        mostRecent.fromDate !== null && mostRecent.toDate !== null || s.fromDate !== null && s.toDate !== null
        ? correctSubs.filter(sFilter => sFilter.fromDate !== null && sFilter.toDate !== null).reduce((mostRecentReduce, sReduce) =>
            (sReduce.toDate <= mostRecentReduce.toDate && sReduce.purchasedAt > mostRecentReduce.purchasedAt)
              ? sReduce
              : mostRecentReduce)
        : s.purchasedAt > mostRecent.purchasedAt ? s : mostRecent
      );
      const fromDate = most_recent.fromDate ? moment(most_recent.fromDate).utc().format("DD.MM.YYYY") : 'Н/Д'
      const toDate = most_recent.toDate ? moment(most_recent.toDate).utc().format("DD.MM.YYYY") : 'Н/Д'
      return {
         last: most_recent.name,
         startDate: fromDate,
         endDate: toDate,
         visitCount: most_recent.visitCount,
         subscriptionCount: most_recent.consumedCount,
      }
    } else {
      if (correctSubs && correctSubs.length) {
        const fromDate = correctSubs[0].fromDate ? moment(correctSubs[0].fromDate).utc().format("DD.MM.YYYY") : 'Н/Д'
        const toDate = correctSubs[0].toDate ? moment(correctSubs[0].toDate).utc().format("DD.MM.YYYY") : 'Н/Д'
        return {
          last: correctSubs[0].name,
          startDate: fromDate,
          endDate: toDate,
          visitCount:  correctSubs[0].visitCount,
          subscriptionCount:  correctSubs[0].consumedCount,
        }
      } else { return null; }
    }
  }
  buttonMessage(phone) {
    return phone.slice(1).replace(/[^0-9]/g, '')
  }

  openViber(clientPhone: string) {
    window.open(`viber://chat?number=%2B${this.buttonMessage(clientPhone)}`);
  }
  async requestChat(id) {
    const chat = await this.chatService.request(id);
    this.chatService.saveRedirectUrl();
    this.router.navigate([`/${this.routingParams.cityId}/${this.routingParams.clubNetId}/${this.routingParams.clubId}/chats/id/${chat.chat.id}`]);
  }
}

interface IClientModelSelected {
  id: string;
  selected: boolean;
}
