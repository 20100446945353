<div class="main-graphs" *ngIf="graphsSummaryInfo">
  <div class="col-12 col-xs-12 widgets-box">
    <div class="col-12 col-xs-12 col-sm-6 col-lg-3 side-total-info" *ngIf="IsShowGraphClients">
      <span class="box-title">Клиенты</span>
      <div class="total-value">
        <span class="total-value__label">Всего клиентов</span>
        <span class="total-value__amount">{{graphsSummaryInfo.clients.totalClients}}</span>
      </div>
      <div class="total-value">
        <span class="total-value__label">Новые за период</span>
        <span class="total-value__amount">{{graphsSummaryInfo.clients.newClients}}</span>
      </div>
      <div class="total-value">
        <span class="total-value__label">Активные за период</span>
        <span class="total-value__amount">{{graphsSummaryInfo.clients.activeClients}}</span>
      </div>
      <div class="total-value" *ngIf="graphsSummaryInfo.clients.municipalClient != null">
        <span class="total-value__label">Отмечено в учете посетителей за период</span>
        <span class="total-value__amount">{{graphsSummaryInfo.clients.municipalClient}}</span>
      </div>
    </div>
    <div class="col-12 col-xs-12 col-sm-6 col-lg-3 graphs-item" *ngIf="IsShowGraphFinance">
      <span class="box-title">Оплаты</span>
      <div class="empty-data" *ngIf="graphsSummaryInfo.finance.total == 0">Нет данных</div>
      <app-graphs-general [type]="chartSummary"
                          [baseData]="financeData"
                          [datalabelsInput]="'center'"
                          [radiusInput]="120"
                          [datalabelsTypeInput]="'any'"
      ></app-graphs-general>
      <ul class="graphs-item__legend" *ngIf="graphsSummaryInfo.finance.total != 0">
        <li><span class="color-marker" [style.background-color]="financeData[0][0]"></span>&nbsp;{{financeData[1][0]}} ({{financeData[2][0].toFixed(2)}})</li>
        <li><span class="color-marker" [style.background-color]="financeData[0][1]"></span>&nbsp;{{financeData[1][1]}} ({{financeData[2][1].toFixed(2)}})</li>
        <li><span class="color-marker" [style.background-color]="financeData[0][2]"></span>&nbsp;{{financeData[1][2]}} ({{financeData[2][2].toFixed(2)}})</li>
        <li><span class="color-marker" [style.background-color]="financeData[0][3]"></span>&nbsp;{{financeData[1][3]}} ({{financeData[2][3].toFixed(2)}})</li>
      </ul>
      <div class="total-value">
        <span class="total-value__amount">{{graphsSummaryInfo.finance.total.toFixed(2)}}</span>
        <span class="total-value__label">Общая сумма</span>
      </div>
    </div>
    <div class="col-12 col-xs-12 col-sm-6 col-lg-3 graphs-item" *ngIf="IsShowGraphEfficiency">
      <span class="box-title">Эффективность</span>
      <div class="d-flex flex-wrap flex-lg-nowrap">
        <div style="margin-right: 1em;">
          <app-graphs-general [type]="chartSummary"
                              [baseData]="efficiencyData"
                              [datalabelsInput]="'center'"
                              [radiusInput]="120"
          ></app-graphs-general>
        </div>
        <ul class="graphs-item__legend">
          <li *ngFor="let item of efficiencyData[0]; index as i">
            <span class="color-marker" [style.background-color]="efficiencyData[0][i]"></span>&nbsp;{{efficiencyData[1][i]}} ({{ efficiencyData[2][i] | number:'1.0-1' }})
          </li>
        </ul>
      </div>
      <div class="total-value">
        <span class="total-value__amount">{{graphsSummaryInfo.efficiency.busy}} из {{graphsSummaryInfo.efficiency.totalWork}}&nbsp;ч.</span>
        <span class="total-value__label">Занято</span>
      </div>
    </div>
    <div class="col-12 col-xs-12 col-sm-6 col-lg-3 graphs-item" *ngIf="IsShowGraphEfficiencyByCourses">
      <span class="box-title">Эффективность по видам</span>
      <div style="margin-right: 1em;">
        <app-graphs-general [type]="chartSummary"
                            [baseData]="efficiencyByCoursesData"
                            [datalabelsInput]="'center'"
                            [radiusInput]="120"
        ></app-graphs-general>
      </div>
      <ul class="graphs-item__legend">
        <li *ngFor="let item of efficiencyByCoursesData[0]; index as i">
          <span class="color-marker" [style.background-color]="efficiencyByCoursesData[0][i]"></span>&nbsp;{{efficiencyByCoursesData[1][i]}} ({{ efficiencyByCoursesData[2][i] | number:'1.0-1' }})
        </li>
      </ul>
      <div class="total-value">
        <span class="total-value__amount">{{graphsSummaryInfo.efficiency.busy}} из {{graphsSummaryInfo.efficiency.totalWork}}&nbsp;ч.</span>
        <span class="total-value__label">Занято</span>
      </div>
    </div>
    <div class="col-12 col-xs-12 col-sm-6 col-lg-3 graphs-item" *ngIf="IsShowGraphMunicipalTask">
      <span class="box-title">Муниципальное задание</span>
      <div class="empty-data" *ngIf="graphsSummaryInfo.municipalTask.municipal === 0 && graphsSummaryInfo.municipalTask.private === 0">Нет данных</div>
      <app-graphs-general [type]="chartSummary"
                          [baseData]="municipalTaskData"
                          [datalabelsInput]="'center'"
                          [radiusInput]="120"
      ></app-graphs-general>
      <ul class="graphs-item__legend" *ngIf="graphsSummaryInfo.municipalTask.municipal !== 0 || graphsSummaryInfo.municipalTask.private !== 0">
        <li><span class="color-marker" [style.background-color]="municipalTaskData[0][0]"></span>&nbsp;{{municipalTaskData[1][0]}} ({{municipalTaskData[2][0]}})</li>
        <li><span class="color-marker" [style.background-color]="municipalTaskData[0][1]"></span>&nbsp;{{municipalTaskData[1][1]}} ({{municipalTaskData[2][1]}})</li>
      </ul>
      <div class="total-value">
        <span class="total-value__amount">{{graphsSummaryInfo.municipalTask.municipal}} из {{graphsSummaryInfo.municipalTask.municipal + graphsSummaryInfo.municipalTask.private}}&nbsp;ч.</span>
        <span class="total-value__label">Муниципальное задание</span>
      </div>
    </div>
    <div class="col-12 col-xs-12 col-sm-6 col-lg-3 side-total-info" *ngIf="IsShowGraphMunicipalTaskText">
      <span class="box-title">Муниципальное задание</span>
      <div class="total-value">
        <span class="total-value__label">Количество людей за период</span>
        <span class="total-value__amount">{{ graphsSummaryInfo.peoplePerDay }}</span>
      </div>
      <div class="total-value">
        <span class="total-value__label">Задание</span>
        <span class="total-value__amount">{{ graphsSummaryInfo.municipalTask.municipal }}</span>
      </div>
    </div>
  </div>
</div>
