import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import { ScheduleService } from './../services/schedule.service';
import { EntityStatus } from 'app/common/models/entity-status.model';
import { ColorService } from 'app/common/services/color.service';
import {Location} from '@angular/common';
import * as helpers from 'app/common/helpers.functions';
import { IClassItemModel } from './../../../common/models/class-item.model';
import { Router, ActivatedRoute } from '@angular/router';
import { PermissionService } from 'app/common/services/permission.service';
import { isEditAvailableKey } from 'app/common/models/permission-key.model';
import {ITableHeader} from '../../../common/models/scroll-models.model';

@Component({
  selector: 'app-compact-schedule-mode',
  templateUrl: './compact-schedule-mode.component.html',
  styleUrls: ['./compact-schedule-mode.component.scss']
})
export class CompactScheduleModeComponent implements OnInit {
  public static readonly componentName: string = 'CompactScheduleModeComponent';

  @Input() schedule: any;
  @Input() clubDownTime: any[] = [];
  @Input() colorBy;
  @Input() isInCopyMode: boolean;
  @Input() isDraftViewMode: boolean;
  @Output() stickHeader = new EventEmitter<boolean>();
  @Input() tableStick: any;

  private newTime;
  private newDate;

  @ViewChild('table') tableView: ElementRef;

  public tableHeader: ITableHeader;
  public heightTableStick: any;

  constructor(
    protected service: ScheduleService,
    private colorService: ColorService,
    public location: Location,
    public router: Router,
    private permissionService: PermissionService,
    public route: ActivatedRoute,
    private el: ElementRef,
  ) { }

  ngOnInit(): void {
    this.newDate = this.service.controlState.baseDate;
    this.newTime = '00.00';

    this.route.queryParams.subscribe(params => {
      if (params.scroll) {
          window.innerWidth >= 992 ?
            document.querySelector('.app_container').scrollTop = params.scroll :
            document.querySelector('.app_container').scrollTop = document.documentElement.scrollTop = params.scroll;
      }
    });
    setTimeout(() => {
      if (window['CoordinatesAppContainer']) {
        document.getElementById('app_container').scroll(window['CoordinatesAppContainer'].x, window['CoordinatesAppContainer'].y);
      }
    }, 1);
    this.tableHeader = {
      position: 0,
      positionBottom: 0,
      offset: 0,
      stick: false,
      leftScroll: 0,
    };
    if(window.innerWidth > 992) {
      const appContainer = document.querySelector('.app_container');
      appContainer.addEventListener('scroll', () => {
        this.stickTableHead(appContainer.scrollTop);
      });
    }
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll() {
    if(window.innerWidth <= 992) {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      this.stickTableHead(scrollPosition);
    }
  }

  public stickTableHead(pageOffset: number) {
    this.tableHeader.position = this.tableView.nativeElement.offsetTop;
    this.tableHeader.positionBottom = this.tableHeader.position + this.tableView.nativeElement.offsetHeight;

    const pageHeaderHeights = window.innerWidth <= 992 ? 0 : 45;

    if(pageOffset + pageHeaderHeights >= this.tableHeader.position && pageOffset <= this.tableHeader.positionBottom - 100){
      this.tableHeader.stick = true;
      this.stickHeader.emit(this.tableHeader.stick)
    } else {
      this.tableHeader.stick = false;
      this.stickHeader.emit(this.tableHeader.stick)
      const marginTop = parseInt(getComputedStyle(document.getElementsByClassName('controls schedule-title__box')[0]).marginTop);
      const marginBottom = parseInt(getComputedStyle(document.getElementsByClassName('dropdown-wrap')[0]).marginBottom);
      this.heightTableStick = String(this.tableStick.offsetHeight + marginBottom + marginTop) + 'px';
    }
  }

  colorise (classItem: any) {
    let colors: any = new Object();
    switch ( this.colorBy ) {
      case 'coach': colors.bg = classItem.coach ? classItem.coach.color : 'gray'; break;
      case 'course': colors.bg = classItem.course ? classItem.course.color : 'gray'; break;
      case 'room': colors.bg = classItem.room ? classItem.room.color : 'gray'; break;
    }
    colors.borderColor = this.colorService.getMatchingSecondaryColor( colors.bg );

    return colors;
  }

  isDraft(status: string) {
    return status === EntityStatus.draft;
  }

  isVirtual(id: string) {
    return id === '00000000000000000000000000000000';
  }

  public isCurrentDay(day: any) {
    return day.setHours(0,0,0,0) === new Date().setHours(0,0,0,0);
  }


  onEditStartEmpty(item, date: Date, time: string): void {
    this.newDate = helpers.date2str(date);
    this.newTime = time.replace(':', '.');
    this.onEditStart(item);
  }

  onEditStart(event: IClassItemModel): void {
    let scrollPosition;
    if(window.innerWidth <= 992) {
      scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    } else {
      scrollPosition = document.querySelector('.app_container').scrollTop;
    }

    if (event != null) {
      if (event.id === '00000000000000000000000000000000') {
        this.router.navigate([`virtual/${event.prototypeId}/${event.startDate}`], {
          relativeTo: this.route,
          queryParams: {scroll: scrollPosition}
        });
      } else {
        this.router.navigate([`${event.id}`], {relativeTo: this.route, queryParams: {scroll: scrollPosition}});
      }
    } else if (this.permissionService.isAvailable(this, isEditAvailableKey)) {
      this.router.navigate([`create/${this.newDate}/${this.newTime}`], {
        relativeTo: this.route,
        queryParams: {scroll: scrollPosition}
      });
    }
  }

  public classesSortOrderInHourseCell(dayItems: Array<IClassItemModel>) {
    return dayItems.sort((a, b) => a.startTime.localeCompare(b.startTime) || a.room?.name?.localeCompare(b.room?.name) || 1);
  }

  isClubDownTime(value: any) {
    if (!this.clubDownTime) {
      return false;
    }
    value = Number(value);
    return this.clubDownTime.filter(x => x.startHoursAlias <= value && x.endHoursAlias > value).length > 0;
  }

  isShowInSchedule(value: any) {
    return value.isShowInSchedule && value.classStatus === 'cancelled';
  }
}


