<header class="content__header">
  <div class="content__header-title">
    <div class="content__back">
      <span class="content__header-title-value content__header-title-value_default">Клиенты</span>
    </div>
    <a class="content__header-link" style="top: 0" *ngIf="isExcelExportAvailable">
        <button class="btn btn-primary btn-sm" type="submit" (click)="export()">
          <span class="btn-primary__desc">В Excel</span>
        </button>
    </a>
  </div>
</header>

<div class="content__body">
  <ng-container *ngIf="Model!=null">
    <div class="clients-container">
      <div *ngIf="mergeGroups && mergeGroups.length" class="table-responsive" style="padding-bottom: 30px">
        <div class="box-title">Возможные дубликаты!</div>
        <div class="row__field-tooltip">Рекомендуем объединить дубликаты в одну запись, так будет удобнее работать со статистикой.
          При объединении будут сгруппированы посещения занятий, абонементы и сообщения. Выберите главный контакт, он послужит источником контактных данных.
          Чтобы контакт перестал попадать в рекомендации используйте функцию «Исключить».
        </div>
        <app-client-merge-items
          [mergeGroups]="mergeGroups"
          (onItemSelect)="Merge($event.clientId, $event.mergeList, $event.group)"
          (onEditStart)="onEditStart($event.item, $event.event)"
          (onAddIgnore)="AddIgnore($event.clientId)"></app-client-merge-items>
      </div>
      <div class="tableSideLinkWrap">
        <div class="row">
          <div class="col-md-7 col-lg-8">
            <div class="table-toolbar">
              <div *ngIf="isEditAvailable && !receivClientIdStack.length" class="clue-text">
                <a class="box-link" (contextmenu)="onEditStart(null , $event)" (click)="onEditStart(null, $event)">Добавить
                  <span class="key">
                    <span app-key-help [key]="'plus'"></span>
                  </span>
                </a>
              </div>
              <ng-container *ngIf="receivClientIdStack.length">
                <div class="d-none d-sm-block">
                <div class="avatar__default legend">
                  <span class="icon-check"><span class="icon-check__left"></span><span class="icon-check__right"></span></span>
                </div>
                </div>
                <div class="clue-text d-none d-sm-block" style="font-size: larger;">➞</div>
                <button class="btn btn-special" (click)="MergePopup(receivClientIdStack[0].id, receivClientIdStack)" [disabled]="receivClientIdStack.length < 2 || isInMerging">Объединить</button>
                <button class="btn btn-special" (click)="DeselectAll()" [disabled]="isInMerging">Отменить</button>
                <ng-container *ngIf="receivClientIdStack.length > 1">
                  <span>
                    {{receivClientIdStack.length}} {{ receivClientIdStack.length | numEndingPipe :"клиент":"клиента":"клиентов"}}
                    ➞
                    {{receivClientIdStack[0]['fullName']}}
                  </span>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="col-md-5 col-lg-4">
            <form>
              <input class="input__text input__text--long" [formControl]="searchTerm" [(ngModel)]="TermSearchValue" autocomplete="off" placeholder="Введите хотя бы два символа" />
              <button class="clear" matSuffix mat-icon-button (click)="TermSearchValue = ''" [disabled] = "!TermSearchValue">
                <mat-icon>{{ TermSearchValue.length > 0 ? 'close' : 'search' }}</mat-icon>
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <div class="sortTable">
          <select class="box-link_wrap select sortSelect" [(ngModel)]="sortOption" (change)="changeSortOptionTable(sortOption)">
            <option [value]="''" disabled>--Выберите фильтр--</option>
            <option [value]="'fullName-asc'">По алфавиту А ➡ Я</option>
            <option [value]="'fullName-desc'">По алфавиту Я ➡ А</option>
            <option [value]="'clubRegisteredAt-desc'">Сначала новые регистрации</option>
            <option [value]="'clubRegisteredAt-asc'">Сначала старые регистрации</option>
            <option [value]="'subscription-desc'">Сначала с абонементами</option>
            <option [value]="'subscription-asc'">Сначала без абонементов</option>
            <option [value]="'email-desc'">Сначала с почтой</option>
            <option [value]="'email-asc'">Сначала без почты</option>
          </select>
        </div>
        <table class="table rowHover client-table">
          <thead>
            <tr>
              <td class="table-cell table-title-cell">Фото</td>
              <td [ngClass]="getCellTitleClass('fullName')" (click)="sortOptionChange('fullName')">ФИО</td>
              <td [ngClass]="getCellTitleClass('email')" (click)="sortOptionChange('email')">Контакты</td>
              <td [ngClass]="getCellTitleClass('subscription')" (click)="sortOptionChange('subscription')">Абонементы</td>
              <td [ngClass]="getCellTitleClass('clubRegisteredAt')" (click)="sortOptionChange('clubRegisteredAt')">Дата регистрации</td>
              <td class="table-cell table-title-cell">Комментарий</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor='let client of Model.clients' [class.selected]="client.selected" [ngClass]="(client.id == tapIdClient) ? 'tooltip _highlight' : ''">

              <td class="table-cell table-cell_avatar" (click)="Select(client)">
                <span class="table-cell__mobile-title">Фото</span>
                <div class="avatar">
                  <div class="avatar__default">
                    <span class="sp sp-user" *ngIf="!client.selected"></span>
                    <span class="icon-check" *ngIf="client.selected && receivClientIdStack[0].id !== client.id"><span class="icon-check__left"></span><span class="icon-check__right"></span></span>
                    <span class="icon-check" *ngIf="client.selected && receivClientIdStack[0].id === client.id"><span class="icon-target__1"></span><span class="icon-target__2"></span></span>
                  </div>
                  <img class="avatar__img" *ngIf="client.primaryImageUrl && !client.selected" [src]="client.primaryImageUrl">
                </div>
              </td>
              <td class="table-cell table-cell_fullname" (click)="onEditStart(client, $event)" (contextmenu)="onEditStart(client,$event)">
                <span class="table-cell__mobile-title">ФИО</span>
                <div>
                  <span class="color" [style.background]="client.color"></span>
                  <span><b>{{client.fullName}}</b></span>
                  <span class="sp sp-sp" *ngIf="client.accountStatus !== 'clubClient' && client.accountStatus === 'registered'" title="Пользователь приложения SP"></span>
                </div>
              </td>

              <td class="table-cell table-cell_phone">
                <span class="table-cell__mobile-title">Контакты</span>
                <div>
                  <div><span *ngIf="client.phone;" class="onRemoveEvent">{{client.phone}}</span></div>
                  <div><span *ngIf="client.email;" class="onRemoveEvent">{{client.email}}</span></div>
                  <div *ngIf="!client.email && !client.phone"><span class="gray ">[не&nbsp;указано]</span></div>
                    <div class="btn-group">
                      <a class="btn btn-link btn-message" rel="whatsapp" href="https://wa.me/{{buttonMessage(client.phone)}}" target="_blank" *ngIf="client.phone">
                        <span class="sp sp-whatsapp"></span>
                      </a>
                      <a class="btn btn-link btn-message" href="https://t.me/+{{buttonMessage(client.phone)}}" target="_blank" *ngIf="client.phone">
                        <span class="sp sp-telega"></span>
                      </a>
                      <a class="btn btn-link btn-message" (click)="requestChat(client.id)" *ngIf="client.accountStatus !== 'clubClient' && client.accountStatus === 'registered'">
                        <span class="sp sp-logo"></span>
                      </a>
                      <a class="btn btn-link btn-message" rel="viber" (click)="openViber(client.phone)" *ngIf="client.phone && isShowBtnViber">
                        <span class="sp sp-viber"></span>
                      </a>
                    </div>
                </div>
              </td>

              <td class="table-cell table-cell_abonement" (click)="onEditStart(client, $event)" (contextmenu)="onEditStart(client,$event)">
                <span class="table-cell__mobile-title">Абонементы</span>
                <div>
                  <div *ngIf="client.subsciption && client.subsciption.length && getLastSubscription(client.subsciption) !== null">
                    <p>{{ getLastSubscription(client.subsciption).last }}</p>
                    <p class="abonement-dates">
                      <span>{{ getLastSubscription(client.subsciption).startDate }}</span>&nbsp;-&nbsp;
                      <span>{{ getLastSubscription(client.subsciption).endDate }}</span>
                    </p>
                    <p class="abonement-count">
                      <span>{{getLastSubscription(client.subsciption).subscriptionCount}} / {{getLastSubscription(client.subsciption).visitCount >=10000 ? '∞' : getLastSubscription(client.subsciption).visitCount}}</span>
                    </p>
                  </div>
                </div>
              </td>

              <td class="table-cell table-cell_registered-date" (click)="onEditStart(client, $event)" (contextmenu)="onEditStart(client,$event)">
                <span class="table-cell__mobile-title">Дата регистрации</span>
                <div>
                  <span *ngIf="client.clubRegisteredAt; else noClubRegisteredAt">{{client.clubRegisteredAt}}</span>
                  <ng-template #noClubRegisteredAt><span class="gray ">[не&nbsp;указано]</span></ng-template>
                </div>
              </td>

              <td class="table-cell table-cell_comment" (click)="onEditStart(client, $event)" (contextmenu)="onEditStart(client,$event)">
                <span class="table-cell__mobile-title">Комментарий</span>
                <div>
                  <span>{{ client.description }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="load-nextpage" *ngIf="Model.clients && Model.clients.length && Model.clients.length < totalItemCount">
        <button class="btn btn-special" (click)="showNextPage()">
          показать еще {{(pageSize
          < (totalItemCount - Model.clients.length)) ? pageSize : (totalItemCount - Model.clients.length) }} {{ (totalItemCount - Model.clients.length)>
            pageSize? 'из ' + (totalItemCount - Model.clients.length) : '' }}
        </button>
      </div>
    </div>
<!--    <div class="example-container mat-elevation-z8">-->

<!--      <mat-table [dataSource]="dataSource" matSort>-->

<!--        &lt;!&ndash; ID Column &ndash;&gt;-->
<!--        <ng-container matColumnDef="id">-->
<!--          <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>-->
<!--          <mat-cell *matCellDef="let row" data-label="id"> {{row.cnt}} </mat-cell>-->
<!--        </ng-container>-->

<!--        &lt;!&ndash; Progress Column &ndash;&gt;-->
<!--        <ng-container matColumnDef="progress">-->
<!--          <mat-header-cell *matHeaderCellDef mat-sort-header> Progress </mat-header-cell>-->
<!--          <mat-cell *matCellDef="let row" data-label="progress"> {{row.progress}}% </mat-cell>-->
<!--        </ng-container>-->

<!--        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>-->
<!--        <mat-row *matRowDef="let row; columns: displayedColumns;">-->
<!--        </mat-row>-->
<!--      </mat-table>-->

<!--      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>-->
<!--    </div>-->
  </ng-container>

</div>

<ng-template #tmplMergeManualGroup let-mergeGroups="mergeGroups" let-clientId="clientId">
  <app-client-merge-items [mode]="'popup'" [mergeGroups]="mergeGroups" [clientId]="clientId"></app-client-merge-items>
</ng-template>
