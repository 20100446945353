<div class="row">
  <ul class="col-xs-12 tab-group">
    <li class="tab-group__item"  [class.active]="selectedTabIndex === 0" (click)="selectedTabChanged({index: 0})">
      Абонементы <span>({{Model.length}})</span>
    </li>
    <li class="tab-group__item"  [class.active]="selectedTabIndex === 1" (click)="selectedTabChanged({index: 1})">
      Архив абонементов <span>({{ModelArchive.length}})</span>
    </li>
  </ul>
</div>

<ng-container [ngSwitch]="selectedTabIndex">
  <div *ngSwitchDefault [@enterTrigger]="enterTriggerState">
    <div class="listEmpty" *ngIf="Model && Model.length == 0">
      <span>Список пуст</span>
    </div>
    <div class="table-responsive" *ngIf="Model && Model.length">
      <table class="table rowHover">
        <thead>
        <tr>
          <td class="table-cell table-title-cell">Название</td>
          <td class="table-cell table-title-cell">Дата начала</td>
          <td class="table-cell table-title-cell">Дата окончания</td>
          <td class="table-cell table-title-cell">Сумма</td>
          <td class="table-cell table-title-cell">Оплачено</td>
          <td class="table-cell table-title-cell">Посещения</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor='let subs of Model'>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Название:</span>{{subs.name}}</td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Дата начала:</span>{{subs.fromDate}}</td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Дата окончания:</span>{{subs.toDate}}</td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Сумма:</span>
            <span *ngIf="subs.paymentSum; else noSum">{{subs.paymentSum}}</span>
            <ng-template #noSum>
              <div class="desc field">
                <span class="gray ">[не&nbsp;указано]</span>
              </div>
            </ng-template>
          </td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Оплачено:</span>{{subs.purchasedAtLocal}}</td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Посещения:</span>{{subs.visitCounter}}/{{subs.visitCount < 10000 ? subs.visitCount : '∞'}}</td>
          <td class="table-cell" style="text-align: end">
            <button class="btn btn-special" (click)="showExtension($event, subs)">Изменить</button>
            <button class="btn btn-special" (click)="showSurcharge($event, subs)" style="margin-left: 5px">Доплата</button>
            <button class="btn btn-special" (click)="showQuickRecord($event, subs)" style="margin-left: 5px"
                    [disabled]="subs.visitCount < 10000 && subs.visitCounter >= subs.visitCount">Списать
            </button>
            <button class="btn btn-special btn-remove" (click)="cancel($event, subs.id)" *ngIf="isEditAvailable" style="margin-left: 5px">В архив</button>
          </td>
<!--          <td class="table-cell">-->
<!--            <button class="btn btn-clear active" (click)="cancel($event, subs.id)" *ngIf="isEditAvailable">-->
<!--            <span class="icon-cross icon-cross_color">-->
<!--              <span class="icon-cross__left"></span>-->
<!--              <span class="icon-cross__right"></span>-->
<!--            </span>-->
<!--            </button>-->
<!--          </td>-->
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngSwitchCase="1" [@enterTrigger]="enterTriggerState">
    <div class="listEmpty" *ngIf="ModelArchive && ModelArchive.length == 0">
      <span>Список пуст</span>
    </div>
    <div class="table-responsive" *ngIf="ModelArchive && ModelArchive.length">
      <table class="table rowHover">
        <thead>
        <tr>
          <td class="table-cell table-title-cell">Название</td>
          <td class="table-cell table-title-cell">Дата начала</td>
          <td class="table-cell table-title-cell">Дата окончания</td>
          <td class="table-cell table-title-cell">Сумма</td>
          <td class="table-cell table-title-cell">Оплачено</td>
          <td class="table-cell table-title-cell">Посещения</td>
          <td></td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor='let subs of ModelArchive'>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Название:</span>
            {{subs.name}}
          </td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Дата начала:</span>{{subs.fromDate}}</td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Дата окончания:</span>{{subs.toDate}}</td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Сумма:</span>
            <span *ngIf="subs.paymentSum; else noSum">{{subs.paymentSum}}</span>
            <ng-template #noSum>
              <div class="desc field">
                <span class="gray ">[не&nbsp;указано]</span>
              </div>
            </ng-template>
          </td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Оплачено:</span>{{subs.purchasedAtLocal}}</td>
          <td class="table-cell" (click)="showExtension($event, subs)"><span class="thead">Посещения:</span>{{subs.visitCounter}}/{{subs.visitCount}}</td>
          <td class="table-cell" style="text-align: end">
            <button class="btn btn-special btn-archive" (click)="returnFromArchive($event, subs)" *ngIf="isEditAvailable">Вернуть из архива</button>
            <button class="btn btn-special btn-remove" (click)="deletedSub($event, subs)" *ngIf="isEditAvailable">Удалить абонемент</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
