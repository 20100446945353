import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AlertsService } from 'app/common/components/alerts/services/alerts.service';
import { ClubsprofileService } from './clubprofile.service';
import { UsersService } from 'app/components/users/services/users.service';
import { AuthService } from 'app/common/services/auth.service';

import { ContextService } from 'app/common/services/context.service';

import { IUserInfo, isAdmin, isManager, isClubManager, isClubAdmin, isClubNetAdmin } from 'app/common/models/user-info.model';
import { IRoutingParams } from 'app/common/models/context.model';
import { IAccount } from 'app/common/models/account.model';
import { ClubsService } from 'app/components/clubs/services/clubs.service';
import { PermissionService } from 'app/common/services/permission.service';
import { PermissionKey } from 'app/common/models/permission-key.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CheckJson } from 'app/common/validators/check-json.validator';
import { NotEmpty } from 'app/common/validators/required-not-empty.validator';


@Component({
  selector: 'app-clubprofile',
  templateUrl: './clubprofile.component.html',
  styleUrls: ['./clubprofile.component.scss'],
})
export class ClubProfileComponent implements OnInit {
  public static readonly componentName: string = 'ClubProfileComponent';

  public isEditAvailable: boolean;
  public isShortVisible: boolean;
  public isSystemRole: boolean;
  public Model;
  public formPaymentSettings: FormGroup;
  public modelPaymentSettingsJsonOriginValue: string;
  public componentReady = false;
  public userInfo: IUserInfo = null;
  public isAdmin: boolean;
  public isClubManager: boolean;
  public context: IRoutingParams;
  public users1: IAccount[];
  public users2: IAccount[];
  public systemUsers: IAccount[];
  public clientsFile: FileList;
  public fileUploadIsEditMode: boolean;

  constructor(
    private alertsService: AlertsService,
    private clubsService: ClubsService,
    private router: Router,
    private service: ClubsprofileService,
    private readonly formBuilder: FormBuilder,
    private authService: AuthService,
    private usersService: UsersService,
    private permissionService: PermissionService,
    private contextService: ContextService
  ) { }

  async ngOnInit() {
    this.context = this.contextService.getRoutingParams();

    let userInfo = await this.authService.getUserInfo();
    this.userInfo = userInfo;
    this.isAdmin = isAdmin(userInfo);
    this.isShortVisible = isAdmin(userInfo);
    this.isSystemRole = isAdmin(userInfo) || isManager(userInfo);
    this.isClubManager = isClubManager(this.userInfo);
    this.isEditAvailable = this.isSystemRole || isClubAdmin(this.userInfo) || isClubNetAdmin(this.userInfo);

    this.Model = await this.service.getData(this.context);
    this.componentReady = true;

    this.modelPaymentSettingsJsonOriginValue = this.Model.paymentSettingsJson;
    this.Model.paymentGatewayType = this.Model.paymentSettingsJson ? 'custom' : '';
    this.formPaymentSettings = this.formBuilder.group({
      gatewayType: [ this.Model.paymentGatewayType ],
      paramsJson: [ this.Model.paymentSettingsJson, [ NotEmpty(), CheckJson() ] ],
    });
    this.formPaymentSettings.setValidators(this.formPaymentCreateValidatorCheckEqualstoOrigin());
    if (!this.isClubManager) {
      this.users1 = this.users2 = await this.usersService.getItemList();
    }
    else {
      setTimeout(() => {
        this.users1 = this.Model.responsibleToRelevanceInfoUser ? [ this.Model.responsibleToRelevanceInfoUser ] : [];
        this.users2 = this.Model.responsibleToWorkWithClientUser ? [ this.Model.responsibleToWorkWithClientUser ] : [];
      }, 500);
    }

    if (this.isAdmin) {
      this.systemUsers = await this.usersService.getSystemUsers();
    }
    else {
      setTimeout(() => this.systemUsers = this.Model.personalManager ? [ this.Model.personalManager ] : [], 500);
    }
    console.log(Object.keys(this.permissionService.getPermissions()));
  }

  async importClients() {
    await this.clubsService.importClients(this.context, this.clientsFile[0])
    this.fileUploadIsEditMode = false;
  }

  delete() {
    this.alertsService.showConfirm({
      header: 'Вы уверены?',
      message: 'Действительно удалить?',
      buttons: [{
        title: 'Да',
        callback: () => {
          this.clubsService
            .changeStatus('deleted')
            .then(() => this.close())
            .catch(() => { });
        }
      }, {
        title: 'Отмена'
      }]
    }, );
  }

  public close() {
    this.router.navigate([`clubs`]);
  }

  public modelChanged() {
    if (this.isEditAvailable) {
      this.service.postData(this.context, this.Model);
    }
  }

  public formPaymentSettingsSave() {
    if (this.isEditAvailable) {
      this.Model.paymentGatewayType = this.formPaymentSettings.get('gatewayType').value;
      this.Model.paymentSettingsJson = this.Model.paymentGatewayType === ''
        ? null
        : this.formPaymentSettings.get('paramsJson').value;
      this.service.postData(this.context, this.Model)
        .then(() => {
          this.modelPaymentSettingsJsonOriginValue = this.Model.paymentSettingsJson;
          this.Model.paymentGatewayType = this.Model.paymentSettingsJson ? 'custom' : '';
          this.formPaymentSettings.setValue({
            gatewayType: this.Model.paymentGatewayType,
            paramsJson: this.Model.paymentSettingsJson,
          });
        });
    }
  }

  public formPaymentSettingsCancel() {
    this.Model.paymentSettingsJson = this.modelPaymentSettingsJsonOriginValue;
    this.Model.paymentGatewayType = this.Model.paymentSettingsJson ? 'custom' : '';
    this.formPaymentSettings.setValue({
      gatewayType: this.Model.paymentGatewayType,
      paramsJson: this.Model.paymentSettingsJson,
    });
  }

  public formPaymentCreateValidatorCheckEqualstoOrigin() {
    return (g: FormGroup) =>
      g.get('gatewayType').value === this.Model.paymentGatewayType && g.get('paramsJson').value === this.Model.paymentSettingsJson
        ? { EqualsToOrigin: { valid: false } }
        : null;
  } 

  public formPaymentErrors(fieldKey: string) {
    const errors = this.formPaymentSettings.get(fieldKey).errors;
    return errors
      ? Object.keys(errors).filter(k => !errors[k].valid).map(k => formPaymentErrors[k])
      : undefined;
  }

  public _isPermit: (alias: string) => boolean = alias => {
    return this.isSystemRole || this.permissionService.isAvailable(this, `clubProfile_${alias}` as PermissionKey, this.userInfo?.role);
  }
}

const formPaymentErrors = { NotEmpty: 'Заполните поле', CheckJson: 'Неверный формат' };
