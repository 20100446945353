import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { resetPasswordRoute } from 'app/routing/routes/reset-password.route';
import { clubanalytics, report, adminAnalytics, adminReport } from 'app/routing/routes/clubanalytics.route';
import { dashboard } from 'app/routing/routes/dashboard.route';
import { addClientToClassRoute } from 'app/routing/routes/add-client-to-class.route';
import { widget } from 'app/routing/routes/widget.route';
import { widgetsRoute, widgetsEditRoute, themesEditRoute, themesNewRoute, clubEventsRoute, clubEventsEditRoute } from 'app/routing/routes/widgets.route';
import { homeRoute } from 'app/routing/routes/home.route';
import { coursesRoute, courseRoute } from 'app/routing/routes/courses.route';
import { coachesRoute, coachRoute } from 'app/routing/routes/coaches.route';
import { coachregistriesRoute, createCoachRegistryRoute, editCoachRegistryRoute } from 'app/routing/routes/coachsalarycalc.route';
import {
  timeTableRoute,
  classItemRoute,
  classItemVirtualRoute,
  classItemCreateRoute
} from 'app/routing/routes/schedule.route';
import { pricesRoute } from 'app/routing/routes/prices.route';
import { clubprofileRoute } from 'app/routing/routes/clubprofile.route';
import { newsRoute } from 'app/routing/routes/news.route';
import {clientsRoute, clientRoute, visitEditRoute} from 'app/routing/routes/clients.route';
import { groupsRoute, groupRoute } from 'app/routing/routes/groups.route';
import { eventsRoute } from 'app/routing/routes/events.route';
import { servicesRoute } from 'app/routing/routes/services.route';
import { clubSettingsRoute, adminSettingsRoute } from 'app/routing/routes/settings.route';
import { documentsRoute } from 'app/routing/routes/documents.route';
import { adminUsersRoute, clubUsersRoute, adminUserRoute, clubUserRoute } from 'app/routing/routes/users.route';
import { loginRoute } from 'app/routing/routes/login.route';
import { clubroomsRoute, clubroomRoute } from 'app/routing/routes/clubrooms.route';
import { clubsRoute, clubRoute, clubCreationRoute } from 'app/routing/routes/clubs.route';
import { clubnetsRoute, clubnetEditRoute } from 'app/routing/routes/clubnets.route';
import { restorePasswordRoute } from 'app/routing/routes/restore-password.route';
import { subscriptionsRoute, subscriptionRoute, subscriptionSaleRoute } from 'app/routing/routes/subscriptions.route';
import { quickRecordRoute } from 'app/routing/routes/quick-record.route';
import { clubMFImportRoute } from 'app/routing/routes/club-mf-import.route';
import { chatsRoute, idChatRoute, userChatRoute, clubChatsRoute, idClubChatRoute, createClubChatRoute, editClubChatRoute, mailingRoute, mailingClubRoute } from 'app/routing/routes/chats.route';
import { supportRoute } from 'app/routing/routes/support.route';
import { citiesRoute, cityEditRoute } from 'app/routing/routes/cities.route';
import { extensionSubscriptionRoute } from 'app/routing/routes/extension-subscription.route';
import { surchargeRoute } from 'app/routing/routes/surcharge.route';
import { imageRoute } from './routes/media.route';
import {registryCreateRoute, registryCreateTypeRoute, registryEditRoute, registryRoute} from './routes/registry.route';
import {collectionsCreateRoute, collectionsEditRoute, collectionsRoute} from './routes/collections.route';
import {financeRoute, financeRegistryElementsRoute} from './routes/finance.route';
import {accountingRoute} from './routes/accounting.route';
import {electronicQueueRoute} from './routes/electronic-queue.route';
import {clubDownTime} from './routes/app-club-down-time.route';
import {certificatesRoute} from './routes/certificates.route';
import {resourceQueueRoute} from './routes/resource-queue.route';
import {webhookCreateRoute, webhookEditRoute, webhookRoute} from './routes/webhook.route';

const routes: Routes = [
  dashboard,
  addClientToClassRoute,
  widget,
  widgetsRoute,
  widgetsEditRoute,
  themesEditRoute,
  themesNewRoute,
  clubEventsRoute,
  clubEventsEditRoute,
  supportRoute,
  clubsRoute,
  clubRoute,
  clubCreationRoute,
  clubanalytics,
  adminAnalytics,
  report,
  adminReport,
  imageRoute,
  homeRoute,
  coursesRoute,
  courseRoute,
  coachesRoute,
  coachRoute,
  coachregistriesRoute,
  createCoachRegistryRoute,
  editCoachRegistryRoute,
  timeTableRoute,
  classItemRoute,
  classItemVirtualRoute,
  classItemCreateRoute,
  pricesRoute,
  clubprofileRoute,
  clientsRoute,
  clientRoute,
  visitEditRoute,
  quickRecordRoute,
  groupsRoute,
  groupRoute,
  eventsRoute,
  servicesRoute,
  adminSettingsRoute,
  clubSettingsRoute,
  documentsRoute,
  newsRoute,
  clubUsersRoute,
  adminUsersRoute,
  clubUserRoute,
  adminUserRoute,
  loginRoute,
  clubroomsRoute,
  clubroomRoute,
  clubsRoute,
  chatsRoute,
  clubChatsRoute,
  citiesRoute,
  cityEditRoute,
  userChatRoute,
  createClubChatRoute,
  editClubChatRoute,
  clubnetsRoute,
  clubnetEditRoute,
  resetPasswordRoute,
  restorePasswordRoute,
  subscriptionsRoute,
  subscriptionRoute,
  subscriptionSaleRoute,
  clubMFImportRoute,
  extensionSubscriptionRoute,
  surchargeRoute,
  idChatRoute,
  idClubChatRoute,
  mailingRoute,
  mailingClubRoute,
  registryRoute,
  registryCreateRoute,
  registryCreateTypeRoute,
  registryEditRoute,
  collectionsRoute,
  collectionsEditRoute,
  collectionsCreateRoute,
  financeRoute,
  financeRegistryElementsRoute,
  accountingRoute,
  electronicQueueRoute,
  clubDownTime,
  certificatesRoute,
  resourceQueueRoute,
  webhookRoute,
  webhookEditRoute,
  webhookCreateRoute,
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'home',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
