import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import { AlertsService } from 'app/common/components/alerts/services/alerts.service';
import { IClientSubscriptionPlan } from 'app/common/models/subscription.model';
import { ClientsService } from 'app/components/clients/services/clients.service';
import { ContextService } from 'app/common/services/context.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTabChangeEvent} from '@angular/material/tabs';
import { PermissionService } from 'app/common/services/permission.service';
import { isEditAvailableKey, PermissionKey } from 'app/common/models/permission-key.model';

@Component({
  selector: 'app-abonement-list',
  animations: [
    trigger('enterTrigger', [
      state('moveLeft', style({ transform: 'translateX(0%)' })),
      transition('void => moveLeft', [ style({ transform: 'translateX(100%)' }), animate('200ms') ]),
      state('moveRight', style({ transform: 'translateX(0%)' })),
      transition('void => moveRight', [ style({ transform: 'translateX(-100%)' }), animate('200ms') ]),
      state('init', style({ })),
      transition('void => init', [ ]),
    ])
  ],
  templateUrl: './abonement-list.component.html',
  styleUrls: ['./abonement-list.component.scss'],
  providers: [ClientsService]
})
export class AbonementListComponent implements OnInit {
  public static readonly componentName: string = 'AbonementListComponent';

  public isEditAvailable: boolean;

  public Model: IClientSubscriptionPlan[];
  public ModelArchive: IClientSubscriptionPlan[];
  public selectedTabIndex = 0;
  public enterTriggerState = 'init';
  constructor(
    private service: ClientsService,
    private router: Router,
    private permissionService: PermissionService,
    private contextService: ContextService,
    private alertsService: AlertsService
  ) { }

  selectedTabChanged(event: MatTabChangeEvent) {
    this.enterTriggerState = this.selectedTabIndex < event.index ? 'moveLeft' : 'moveRight';
    event.index === 2;
    this.selectedTabIndex = event.index;
  }

  showExtension(e: any, subs:IClientSubscriptionPlan)  {
    if (!this.isEditAvailable) return;
    e.preventDefault();
    let url = '/'+this.contextService.makeContextUrl(`clubclients/${subs.clientId}`+'/extension');
    this.router.navigate([url], { queryParams: {subsId: subs.id }});
  }

  showSurcharge(e: MouseEvent, subs:IClientSubscriptionPlan) {
    if (!this.isEditAvailable) return;
    e.preventDefault();
    let url = '/'+this.contextService.makeContextUrl(`clubclients/${subs.clientId}`+'/surcharge');
    this.router.navigate([url], { queryParams: {subsId: subs.id }});
  }

  showQuickRecord(e: any, subs:IClientSubscriptionPlan) {
    if (!this.isEditAvailable) return;
    e.preventDefault();
    let url = '/'+this.contextService.makeContextUrl(`clubclients/${subs.clientId}`+'/quickrecord');
    this.router.navigate([url], { queryParams: {subsId: subs.id }});
  }

  ngOnInit() {
    this.selectedTabIndex = 0;
    this.isEditAvailable = this.permissionService.isAvailable(this, isEditAvailableKey);
    this.refresh();
  }

  cancel(e: MouseEvent, planId: string) {
    if (!this.isEditAvailable) return;
    e.preventDefault();
    this.alertsService.showConfirm({
      header: 'Вы уверены?',
      message: 'Отправить абонемент в архив?',
      buttons: [{
        title: 'Да',
        callback: () => {
          this.service
            .cancelClientSubscription(planId)
            .then(() => this.refresh())
            .catch(() => { });
        }
      }, {
        title: 'Отмена'
      }]
    }, );
  }

  refresh() {
    this.service.getClientAbonements()
      .then(res => {
        let _data = res['clientSubscriptions'].filter(x => x.status === 'Created' || x.status === 'Active');
        let _dataArchive = res['clientSubscriptions'].filter(x => x.status === 'Cancelled' || x.status === 'Suspended' || x.status === 'Expired');
        let t = _dataArchive.sort((a, b) => this.srt(a, b))
        this.Model = _data
        this.ModelArchive = t
      });
  }

  public srt(a: IClientSubscriptionPlan, b: IClientSubscriptionPlan)
  {
    /*if (a === null || a.fromDate === null)
      return 1;
    if (b === null || b.fromDate === null)
      return -1;
    let fromA = new Date(a.fromDate.split('.').reverse().join('-'))
    let fromB = new Date(b.fromDate.split('.').reverse().join('-'))*/
    let toA = new Date(a.toDate.split('.').reverse().join('-'))
    let toB = new Date(b.toDate.split('.').reverse().join('-'))

    if (toA >= toB) return -1;
    else return 1;
    return 0;
  }

  returnFromArchive(e: MouseEvent, subs: IClientSubscriptionPlan) {
    if (!this.isEditAvailable) return;
    e.preventDefault();
    this.alertsService.showConfirm({
      header: 'Вы точно хотите вернуть абонемент из архива?',
      message: 'Абонемент будет восстановлен, отредактируйте его или он снова уйдет в архив в течении суток',
      buttons: [{
        title: 'Да',
        callback: () => {
          this.service
            .returnSubscriptionFromArchive(subs)
            .then(() => { this.refresh() })
            .catch(() => { });
        }
      }, {
        title: 'Отмена'
      }]
    }, );
  }

  deletedSub(e: MouseEvent, subs: IClientSubscriptionPlan) {
    if (!this.isEditAvailable) return;
    e.preventDefault();
    this.alertsService.showConfirm({
      header: 'Вы точно хотите удалить абонемент из архива?',
      message: 'Абонемент будет удален, и не будет отображен в аналитике',
      buttons: [{
        title: 'Да',
        callback: () => {
          this.service
            .deletedClientSubscription(subs.id)
            .then(() => { this.refresh() })
            .catch(() => { });
        }
      }, {
        title: 'Отмена'
      }]
    }, );
  }
}
